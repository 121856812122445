var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "containerRef",
      staticClass: "container gnb-h-90",
      class: _vm.$style.container,
      on: { scroll: _vm.getDataOnScroll }
    },
    [
      _c("div", { class: _vm.$style.topBanner }, [
        _c(
          "div",
          { class: _vm.$style.img },
          [
            _c("Icon", {
              attrs: {
                src: "img/offer/draft.png",
                width: "55px",
                height: "64px"
              }
            })
          ],
          1
        ),
        _c("div", { class: _vm.$style.text }, [
          _c("div", { class: _vm.$style.title }, [
            _vm._v("1:1 문의 빠르게 보내는 방법")
          ]),
          _c("div", { class: _vm.$style.desc }, [
            _vm._v(" 등록했던 의뢰와 문의할 내용이 같다면?"),
            _c("br"),
            _vm._v(" 새로 작성할 필요없어요."),
            _c("br"),
            _vm._v(" 보낼 내용(의뢰)를 선택만 하면 끝! ")
          ])
        ])
      ]),
      _c(
        "ul",
        { class: _vm.$style.offerList },
        [
          !_vm.offerState.isMounted || _vm.offerState.list.length
            ? [
                _vm._l(_vm.offerState.list, function(row, key) {
                  return [
                    _c("OItem", {
                      key: "" + key,
                      attrs: { row: row, registered: _vm.postOfferGenre }
                    }),
                    _c("div", {
                      key: key + ":line",
                      staticClass: "ui-border-line",
                      staticStyle: { height: "10px" }
                    })
                  ]
                })
              ]
            : _c("BlankList", {
                attrs: {
                  text:
                    "아직 등록한 의뢰가 없으시네요:)\n원하는 조건 등록하고\n맞춤 견적을 받아보세요!"
                }
              })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }