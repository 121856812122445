// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#RegisteredOffer_page_1Xtuf > .RegisteredOffer_container_28z0i {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  padding-bottom: 0;\n}\n#RegisteredOffer_page_1Xtuf > .RegisteredOffer_container_28z0i .RegisteredOffer_offerList_1XT26 {\n  padding: 0 3rem;\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n.RegisteredOffer_topBanner_LPiif {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  background-color: #2bc4cc;\n  padding: 3rem 1.2rem;\n  color: white;\n  margin: 0 -3rem;\n}\n.RegisteredOffer_topBanner_LPiif .RegisteredOffer_img_1eB54 {\n  width: 135px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n.RegisteredOffer_topBanner_LPiif .RegisteredOffer_text_2-_Bc {\n  line-height: normal;\n}\n.RegisteredOffer_topBanner_LPiif .RegisteredOffer_text_2-_Bc .RegisteredOffer_title_3sPMW {\n  font-size: 24px;\n  font-weight: 600;\n  padding: 0 0 8px;\n}\n.RegisteredOffer_topBanner_LPiif .RegisteredOffer_text_2-_Bc .RegisteredOffer_desc_z5nKl {\n  font-size: 18px;\n  font-weight: normal;\n}", ""]);
// Exports
exports.locals = {
	"page": "RegisteredOffer_page_1Xtuf",
	"container": "RegisteredOffer_container_28z0i",
	"offerList": "RegisteredOffer_offerList_1XT26",
	"topBanner": "RegisteredOffer_topBanner_LPiif",
	"img": "RegisteredOffer_img_1eB54",
	"text": "RegisteredOffer_text_2-_Bc",
	"title": "RegisteredOffer_title_3sPMW",
	"desc": "RegisteredOffer_desc_z5nKl"
};
module.exports = exports;
