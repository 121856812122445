<template>
    <div
        ref="containerRef"
        class="container gnb-h-90"
        :class="$style.container"
        @scroll="getDataOnScroll"
    >
        <div :class="$style.topBanner">
            <div :class="$style.img">
                <Icon src="img/offer/draft.png" width="55px" height="64px" />
            </div>
            <div :class="$style.text">
                <div :class="$style.title">1:1 문의 빠르게 보내는 방법</div>
                <div :class="$style.desc">
                    등록했던 의뢰와 문의할 내용이 같다면?<br />
                    새로 작성할 필요없어요.<br />
                    보낼 내용(의뢰)를 선택만 하면 끝!
                </div>
            </div>
        </div>

        <ul :class="$style.offerList">
            <template v-if="!offerState.isMounted || offerState.list.length">
                <template v-for="(row, key) in offerState.list">
                    <OItem
                        :key="`${key}`"
                        :row="row"
                        :registered="postOfferGenre"
                    />
                    <div
                        class="ui-border-line"
                        :key="`${key}:line`"
                        style="height: 10px;"
                    />
                </template>
            </template>
            <BlankList
                v-else
                :text="
                    `아직 등록한 의뢰가 없으시네요:)\n원하는 조건 등록하고\n맞춤 견적을 받아보세요!`
                "
            />
        </ul>
    </div>
</template>

<script>
import {
    computed,
    defineComponent,
    getCurrentInstance,
    onActivated,
    onBeforeUnmount,
    onDeactivated,
    onMounted,
    reactive,
    ref,
} from '@vue/composition-api'
import { Subject } from 'rxjs'
import { throttleTime } from 'rxjs/operators'

import OItem from '@/components/client/OItem.vue'
import BlankList from '@/components/blank/BlankList.vue'
import Icon from '@/components/common/Icon.vue'

export default defineComponent({
    components: {
        OItem,
        BlankList,
        Icon,
    },
    name: 'ClientRegisteredOffer',
    setup() {
        const vue = getCurrentInstance()
        const that = vue.proxy

        const lifecycle$ = new Subject()

        /**
         * @type {import('@vue/composition-api').Ref<HTMLDivElement>}
         */
        const containerRef = ref(null)

        const offerState = reactive({
            list: [],
            offset: 0,
            isMounted: false,
            que: false,
            scroll: {
                lastY: 0,
            },
        })

        /**
         * @type {import('@vue/composition-api').ComputedRef<string | null>}
         */
        const postOfferGenre = computed(() => {
            return vue.proxy.$store.state?.postOffer?.genre ?? null
        })

        onMounted(() => {
            lifecycle$
                .pipe(
                    throttleTime(500, undefined, {
                        trailing: true,
                    })
                )
                .subscribe((lifecycle) => {
                    switch (lifecycle) {
                        case 'activated': {
                            const lastY = offerState.scroll.lastY
                            const isMounted = offerState.isMounted

                            if (isMounted) {
                                if (containerRef.value) {
                                    containerRef.value.scrollTop = lastY
                                }
                            } else {
                                getOfferData(true)
                            }

                            that.$store.commit('setGnbRightBtnCb', () => {
                                that.$router.push(`/offer/post/0`)
                            })
                            break
                        }

                        case 'deactivated': {
                            that.$store.commit('setGnbRightBtnCb', null)
                            break
                        }
                    }
                })
        })

        onActivated(() => {
            lifecycle$.next('activated')
        })

        onDeactivated(() => {
            lifecycle$.next('deactivated')
        })

        onBeforeUnmount(() => {
            lifecycle$.unsubscribe()
        })

        const getOfferData = (isInit) => {
            if (offerState.que) return
            offerState.que = true

            if (isInit) offerState.offset = 0

            const req = {
                method: 'post',
                url: `/client/myOffer/${offerState.offset}/20`,
                data: {
                    mode: '',
                    profile: 'y',
                    proPrice: 'n',
                    nowState: 'ing,cancel,wait,selfEnd,autoEnd,end',
                },
            }

            return that
                .$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = that.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        const offerData = data?.response?.offerData || []
                        offerState.list = isInit
                            ? offerData
                            : offerState.list.concat(offerData)
                        offerState.offset += offerData.length
                    } else {
                        that.$alert(resultData)
                    }
                    return
                })
                .catch((e) => {
                    log.error(e)
                    return
                })
                .then(() => {
                    offerState.isMounted = true
                    setTimeout(() => {
                        offerState.que = false
                    }, 1000)
                    return
                })
        }

        const getDataOnScroll = () => {
            if (!containerRef.value) return

            const { scrollTop, scrollHeight } = containerRef.value

            offerState.scroll.lastY = scrollTop

            if (scrollHeight <= scrollTop + window.innerHeight) {
                getOfferData()
            }
        }

        return {
            containerRef,
            offerState,
            postOfferGenre,
            getDataOnScroll,
        }
    },
})
</script>

<style lang="scss" module>
#page > .container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;

    .offerList {
        padding: 0 3rem;
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.topBanner {
    display: flex;
    background-color: #2bc4cc;
    padding: 3rem 1.2rem;
    color: white;
    margin: 0 -3rem;

    .img {
        width: 135px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .text {
        line-height: normal;

        .title {
            font-size: 24px;
            font-weight: 600;
            padding: 0 0 8px;
        }
        .desc {
            font-size: 18px;
            font-weight: normal;
        }
    }
}
</style>
